import React, { createContext, useContext, useEffect, useState } from 'react';

import { AccountType } from 'models/enums/UserEnums';
import { UserContext } from 'state/containers/UserContainer';

interface IPortfolioContext {
    canCreatePortfolio: boolean,
}


export const PortfolioContext = createContext<IPortfolioContext>({
    canCreatePortfolio: false
});


const PortfolioContainer = ({ children }: { children: React.ReactNode }) => {

    const userContainer = useContext(UserContext);

    const [canCreatePortfolio, setCanCreatePortfolio] = useState<boolean>(false);

    useEffect(() => {
        let canCreate = false;
        const organization_config = userContainer.user?.organization_config;
        const portfolio_count = userContainer.user?.portfolio_count;

        if (organization_config && typeof organization_config.portfolio_with_ped === 'boolean') {
            canCreate = organization_config.portfolio_with_ped;
        }
        else if (userContainer.hasAccountType([AccountType.BASIC])) {
            canCreate = portfolio_count === 0 ? true : false;
        } else {
            canCreate = userContainer.hasAccountType([AccountType.ENTERPRISE_AGENT, AccountType.ENTERPRISE_MASTER,
            AccountType.PRO_UNLIMITED, AccountType.PRO_ON_DEMAND]) ? true : false;
        }
        setCanCreatePortfolio(canCreate);

    }, [userContainer.user]);


    return (
        <PortfolioContext.Provider value={{ canCreatePortfolio }}>
            {children}
        </PortfolioContext.Provider>
    );
};

export default PortfolioContainer;