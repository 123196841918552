// @ts-nocheck
import { PortfolioTypes } from 'models/enums/DocumentEnums';
import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { AccountType } from 'models/enums/UserEnums';
import xIcon from 'static/images/x.svg';
import { IoChevronBack } from "react-icons/io5";

const blue = "#2e5bff";
const lightBlue = "#e8eeff";
const white = "#ffffff";
const skyBlue = "#1acefc";
const brightRed = "#e51414";
const green = "#08ca86";

export const Button = styled.button<{ color?: string, minheight?: string }>`
    border-radius: 3px;
    background-color: ${props => {
        switch (props.color) {
            case "skyBlue":
                return skyBlue;
            case "white":
                return white;
            case "green":
                return green;
            default:
                return blue;
        }
    }};
    color: ${props => props.color === "white" ? blue : white};
    font-family: "GilroySemiBold";
    font-size: 16px;
    text-align: center;
    border: ${props => props.color === "skyBlue" ? 'none' : `${blue} 1px solid`};
    min-height: ${props => props.minheight ? `${props.minheight}` : '40px'};
    padding: 0 15px;
    
    &:focus{
        outline:none;
    }

    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const SecondaryButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
  background-color: ${lightBlue};
  font-family: "GilroyBold";
  color: ${blue};
  border: none;

  img{ margin-right: 8px !important; }
`;

export const BackButton = ({ onClick, text }: { onClick: () => void, text: string }) => {
    return (
        <button className="secondary-button flex items-center gap-1" onClick={onClick}>
            <IoChevronBack size={"21px"} title='left arrow' />
            {text}
        </button>
    );
};

export const TertiaryButton = styled.button`
    border: none;
    background: none;
    color: ${props => props.color ? props.color : blue};
    font-family: "GilroyBold";
    font-size: 16px;
    text-decoration: underline;
    text-align: center;
    padding: 0;

    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const AccountBadge = styled.div<{ account: string }>`
    font-family: "GilroySemiBold";
    text-align: center;
    font-size: 14px;
    white-space: nowrap;

    color: ${white};
    height: 20px;
    padding: 0 10px;
    border-radius: 12.5px;
    background-color: ${props => {
        switch (props.account) {
            case AccountType.BASIC: return "#2e5bff";
            case AccountType.PRO_ON_DEMAND: return "#2e9bff";
            case AccountType.PRO_UNLIMITED: return "#1acefc";
            case AccountType.ENTERPRISE_MASTER || AccountType.ENTERPRISE_AGENT: return "#ffb62e";
            default: return "#2e5bff";
        }
    }};
`;

export const Badge = styled.span<{ backgroundColor: string }>`
  padding: 2px 10px;
  border-radius: 9px;
  height: 26px;
  background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : skyBlue};
  font-family: "GilroyBold";
  font-size: 14px;
  text-align: center;
  color: ${white};
  white-space: nowrap;
`;


interface ChipProps {
    children?: React.ReactNode;
    color: string; // hex color
    solid: boolean;
    style?: React.CSSProperties; //style object to extend the component
}
export const Chip: React.FC<ChipProps> = ({ children, color, solid, style }) => {

    const ChipSpan = styled.span<{ color: string, solid: boolean }>`
        padding: 2px 10px;
        border-radius: 1rem;
        border: ${(props) => `2px solid ${props.color}`};
        height: 26px;
        background-color: ${(props) => props.solid ? props.color : "none"};
        font-family: "GilroyBold";
        font-size: 14px;
        text-align: center;
        color: ${(props) => props.solid ? white : props.color};
        white-space: nowrap;
    `;
    return (<ChipSpan color={color} solid={solid} style={style}>{children}</ChipSpan>);
}

interface PortfolioTypeProps { portfolioType: PortfolioTypes, style?: React.CSSProperties }
export const PortfolioTypeChip: React.FC<PortfolioTypeProps> = ({ portfolioType, style }) => {
    const text = portfolioType === PortfolioTypes.GESTION_LOCATIVE_PORTFOLIO ? "Gestion" : "Vente";
    const color = portfolioType === PortfolioTypes.GESTION_LOCATIVE_PORTFOLIO ? green : skyBlue;
    return (<Chip color={color} solid={false} style={style}>{text}</Chip>);
}


export const BoldLink = styled.span<{ fontSize: string }>`
    font-family: "GilroyBold";
    font-size: ${props => props.fontSize || "16px"};
    color: ${blue} !important;
    text-decoration:underline;
    cursor: pointer;
`;

/**
 * Hoverable tooltip, valid props:
 * text: content of the tooltip message
 * position: position of the tooltop [top, bottom, left, right]
 * width: width in pixels
 */
export const Tooltip: React.FC<{ position: string, width: number, text: string, children?: React.ReactNode }> =
    ({ position, width, text, children }) => {

        const TooltipContainer = styled.div<{ position: string, width?: number }>`
        position: relative;
        display: inline-block;
    
        .tooltip-text{
            visibility: hidden;
            width: ${props => props.width}px;
            background-color: white;
            color: #2e5bff;
            text-align: center;
            padding: 7px 7px;
            border-radius: 6px;
            border: 1px solid #2e5bff;
            font-family: "GilroyMedium";
            position: absolute;
            z-index: 1;

            ${props => {
                switch (props.position) {
                    case "top":
                        return ({
                            bottom: '100%',
                            left: '50%',
                            marginLeft: `-${props.width ? props.width / 2 : ''}px` // Use half of the width (120/2 = 60), to center the tooltip
                        });
                    case "bottom":
                        return ({
                            top: '100%',
                            left: '50%',
                            marginLeft: `-${props.width ? props.width / 2 : ''}px` // Use half of the width (120/2 = 60), to center the tooltip
                        });
                    case "left":
                        return ({
                            top: '-5px',
                            right: '105%',
                        });
                    case "right":
                        return ({
                            top: '-5px',
                            left: '105%',
                        });
                    default: break;
                }
            }}
        }
    
        &:hover .tooltip-text{
            visibility: visible;
        }
    `;
        return (
            <TooltipContainer position={position}>
                {children}
                <span className="tooltip-text">{text}</span>
            </TooltipContainer>);
    };


export const Modal = styled(Popup)`
    // use your custom style for ".popup-overlay"
    &-overlay {
        background: rgba(28, 39, 59, 0.9) !important;
    }
    // use your custom style for ".popup-content"
    &-content {
        width: 90% !important;
        max-width: 40rem;
        padding: 15px 20px !important;
        overflow-y: auto;
        max-height: 90%; 
    }
`;

const ModalHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 15px;

    #header-title{
        font-family: 'RubikMedium';
        color: #2e384d;
    }
    #popup-close-button{
        margin-left: 5px;
        height: 20px;
        cursor: pointer;
    }
`;

export const ModalHeader = ({ title, close }: { title: string, close: Function }) => {
    return (
        <ModalHeaderContainer>
            <div></div>
            <div><span id="header-title">{title}</span></div>
            <div><img src={xIcon} onClick={close} id='popup-close-button' alt="Close" /></div>
        </ModalHeaderContainer>
    );
};


export const ConfirmationModal = ({ trigger, callback, title }) => {
    const buttonStyle = {
        margin: '0 5px',
        width: '150px'
    };

    return (
        <Modal trigger={trigger} modal closeOnDocumentClick>
            {close =>
                <>
                    <ModalHeader title={title} close={close} />
                    <div id="inner-container" className="rem-2-top-margin" style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <SecondaryButton className="rem-1-top-margin" onClick={close} style={buttonStyle}>Annuler</SecondaryButton>
                        <Button className="rem-1-top-margin" onClick={() => { callback(); close(); }} style={buttonStyle}>Accepter</Button>
                    </div>
                </>
            }
        </Modal >
    );
};


// FORM COMPONENTS

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormInputStrech = styled(FormInput)`
    margin: 10px;
    width: ${props => props.stretch ? '100%' : '50%'};
    @media (max-width: 600px) { width:100%; }
`;

export const InputLabel = styled.label`
  height: 26px;
  font-family: "GilroySemiBold";
  font-size: 15px;
  line-height: 1.73;
  color: #1a273d;
`;

export const TextArea = styled.textarea`
    font-size: 1rem;
    border-radius: 3px;
    font-family: "GilroyMedium";
    border: solid 1px #2e5bff19;
    background-color: #ffffff;
    padding: 8px;

    &:focus{
        border: solid 1px ${blue};
        outline: none;
    }

    &:focus{
        border: solid 1px ${blue};
        outline: none;
    }

    &:not(:empty) { border: solid 1px ${blue}; }

    &::placeholder {
        opacity: 0.5;
        font-family: "GilroyLight";
        font-size: 17px;
        line-height: 1.53;
        color: #1a273d;
    }
`;

export const Input = styled.input`
    height: 51px;
    border-radius: 3px;
    font-family: "GilroyMedium";
    border: solid 1px #2e5bff19;
    background-color: ${white};
    padding-left: 8px;
    /* Removes IOS default input styling */
    -webkit-appearance: none;

    &:focus{
        border: solid 1px ${blue};
        outline: none;
    }

    &:not([value='']) { border: solid 1px ${blue}; }

    &::placeholder {
        opacity: 0.5;
        font-family: "GilroyLight";
        font-size: 17px;
        line-height: 1.53;
        color: #1a273d;
    }
`;

export const InputError = styled.ul`
    font-family: "RubikMedium";
    font-size: 13px;
    color: ${brightRed};
    margin: 0 10px;
    padding-left: 15px;

    &>li{ margin-top: 5px;}
`;

export const InlineContainer = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

export const SemiBoldText = styled.span<{ fontSize: string }>`
    font-family: 'GilroySemiBold';
    font-size: ${props => props.fontSize || '16px'};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e384d;
`;


const BlockQuote = styled.blockquote`
    position: relative;
	padding-left: 30px;
	font-weight: 900;
    font-family: 'GilroyBold';
	color: #2560ff;
	padding: 1em;
	margin: 1em;
	max-width: 33em;
	font-size: 22px;
	&:before {
		content: "“";
		font-family: serif;
		position: absolute;
		right: 100%;
		font-size: 120px;
		line-height: 0px;
		top: 50px;
		color: #78e08f;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		width: 80px;
		height: 5px;
		background: #e56d39;
		left: 1em;
	}
`;

export function Quote(props) {
    return (
        <div style={{ paddingLeft: '50px' }}>
            <BlockQuote>{props.children}</BlockQuote>
        </div>
    );
}