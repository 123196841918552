import CookieConsentBanner from 'components/common/CookieConsentBanner';
import Footer from 'components/common/Footer';
import PageNotFound from 'components/common/PageNotFound';
import AuthValidation from 'components/common/auth/AuthValidation';
import PolicyConsent from 'components/home/PolicyConsent';
import Landing from 'components/home/landing/Landing';
import LogIn from 'features/authentication/login';
import SignUp from 'features/authentication/signup';

import PasswordReset from 'components/home/login/PasswordReset';
import ValidateInvitationLink from 'components/portfolio/invitations/ValidateInvitationLink';
import config from "config";
import { isCookieAllowed } from 'helpers/cookieConsentmentUtils';
import { startCrispChat } from 'helpers/utils';
import React, { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Analytics from 'react-router-ga';
import AuthService from 'services/AuthService';
import { UserContext } from "state/containers/UserContainer";

import 'static/styles/App.css';


// LANDING
const PedLanding = React.lazy(() => import('components/home/ped/PedLanding'));

// DASHBOARD
const Dashboard = React.lazy(() => import('components/portfolio/Dashboard'));
const SyndicScraping = React.lazy(() => import('components/portfolio/scraping/syndic/SyndicScraping'));
const ImpotsConnection = React.lazy(() => import('components/portfolio/scraping/impots/ImpotsConnection'));
const MissionContainer = React.lazy(() => import('components/portfolio/misions/MissionsContainer'));
const PEDMissionContainer = React.lazy(() => import('components/portfolio/misions/PEDMissionsContainer'));
const DownloadPortfolio = React.lazy(() => import('components/portfolio/DownloadPortfolio'));

// ACCOUNT
const UserAccount = React.lazy(() => import('components/account/UserAccount'));
const AddPortfolio = React.lazy(() => import('components/account/portfolios/AddPortfolio'));
const AddUser = React.lazy(() => import('components/account/guests/AddUser'));
const OnboardProspect = React.lazy(() => import('features/prospectOnboarding/index'));

// LEGAL
const TermsOfUse = React.lazy(() => import('components/legal/TermsOfUse'));
const Cookies = React.lazy(() => import('components/legal/Cookies'));
const BecomeAPartner = React.lazy(() => import('components/legal/BecomeAPartner'));
const About = React.lazy(() => import('components/legal/About'));
const Confidentiality = React.lazy(() => import('components/legal/Confidentiality'));
const LegalMentions = React.lazy(() => import('components/legal/LegalMentions'));
const FAQ = React.lazy(() => import('components/legal/FAQ'));
const CGV = React.lazy(() => import('components/legal/CGV'));
const CGVPreEtatDate = React.lazy(() => import('components/legal/CGVPreEtatDate'));

// PAYMENT
const Plans = React.lazy(() => import('components/payment/Plans'));
const ClientDetails = React.lazy(() => import('components/payment/ClientDetails'));
const ConfirmationMessage = React.lazy(() => import('components/payment/ConfirmationMessage'));
const OrderPED = React.lazy(() => import('features/orderPED/OrderPED'));
const PublicPricing = React.lazy(() => import('components/common/pricing/PublicPricing'));


/**
 * Wrapper for protected routes, if a route should be visible only to logged in users this
 * component verifies that the user is authenticated, if not he is redirected to the login.
 */
const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route {...rest} render={(props) => (
    AuthService.isAuthenticated() === true ? <Component {...props} />
      : <Redirect to={{ pathname: '/connexion', state: { from: props.location } }} />
  )} />
)

// Component that connects to google analytics only when the user acepted the cookies
const GoogleAnalyticsGDPR = ({ children }: any) => {
  const userGaveCookieConsent = isCookieAllowed();
  return userGaveCookieConsent ? <Analytics id={config.GOOGLE_ANALYTICS_ID}>{children}</Analytics> : children;
};

/**
 * Lightweight component to scroll page to top in every react router redirect.
 */
const ScrollToTop = withRouter(
  function _ScrollToTop({ history }) {
    useEffect(() => {
      const unlisten = history.listen(() => { window.scrollTo(0, 0); });
      return () => { unlisten(); }
    }, [history]);
    return (null);
  });

const App = () => {
  const { allowedCookies } = useContext(UserContext);
  // Load crisp chat when user accepted the cookie consent
  useEffect(() => {
    if (allowedCookies) startCrispChat();
  }, [allowedCookies])

  return (
    <>
      <div id="main-content">
        <BrowserRouter>
          <ScrollToTop />
          <GoogleAnalyticsGDPR>
            {/* IMPORTANT: WHEN ADDING/MODIFYING URLS UPDATE THE SITEMAP AND REGENERATE IT */}

            <Suspense fallback={<div></div>}>


              <Switch>
                <Route exact path="/" component={Landing} />

                <Route exact path="/inscription" component={SignUp} />
                <Route exact path="/connexion" component={LogIn} />
                <Route exact path="/password/reset" component={PasswordReset} />


                <Route exact path="/offrepro" component={PublicPricing} />
                <Route exact path="/message" component={PageNotFound} />
                <Route exact path="/conditions" component={PolicyConsent} />
                <Route exact path="/preetatdate" component={PedLanding} />

                <Route exact path="/auth" component={AuthValidation} />
                <Route exact path="/invitation/:invitationToken" component={ValidateInvitationLink} />
                <Route exact path="/onboard/:commercialProspectId" component={OnboardProspect} />

                <PrivateRoute exact path="/dashboard/:portfolioUid" component={Dashboard} />
                <PrivateRoute exact path="/dashboard/:portfolioUid/syndic" component={SyndicScraping} />
                <PrivateRoute exact path="/dashboard/:portfolioUid/impots" component={ImpotsConnection} />
                <PrivateRoute exact path="/dashboard/:portfolioUid/missions" component={MissionContainer} />
                <PrivateRoute exact path="/dashboard/:portfolioUid/missionsped" component={PEDMissionContainer} />
                <PrivateRoute exact path="/dashboard/:portfolioUid/download" component={DownloadPortfolio} />

                {/* One time payment products */}
                <PrivateRoute exact path="/dashboard/:portfolioUid/preetatdate" component={OrderPED} />

                <PrivateRoute exact path="/account" component={UserAccount} />
                <PrivateRoute exact path="/account/nouveauportfolio" component={AddPortfolio} />
                <PrivateRoute exact path="/account/nouveauutilisateur" component={AddUser} />

                {/* Payment (TODO: CHECK y cambiar urls a frances!!!!) */}
                <PrivateRoute exact path="/payment/offre" component={Plans} />
                <PrivateRoute exact path="/payment/:productId/clientdetails" component={ClientDetails} />
                <PrivateRoute exact path="/payment/success" component={ConfirmationMessage} />

                {/* Legal */}
                <Route exact path="/cgu" component={TermsOfUse} />
                <Route exact path="/cookies" component={Cookies} />
                <Route exact path="/devenirpartenaire" component={BecomeAPartner} />
                <Route exact path="/apropos" component={About} />
                <Route exact path="/confidentialite" component={Confidentiality} />
                <Route exact path="/mentionslegales" component={LegalMentions} />
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/cgv" component={CGV} />
                <Route exact path="/cgvPED" component={CGVPreEtatDate} />

                {/* Error pages */}
                <Route render={() => <PageNotFound />} />
              </Switch>
            </Suspense>
          </GoogleAnalyticsGDPR>
        </BrowserRouter>
      </div>

      <div className="notification-centered">
        <div id="notification-fixed-container" >
          <CookieConsentBanner />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
